@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;

  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

* input {  
  -webkit-user-select: text;
  -ms-user-select: text;
  -o-user-select: text;
  user-select: text;
}

.btn-add-remove-quantity {
  font-size: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-remove-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
}

button {
  border-radius: 0px !important;
}

html,
body,
#root {
  min-height: 100%;
  -webkit-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
  padding: 0!important;
  margin: 0!important;
}

body {
  background: #fafafa;
  background-size: cover;
  -webkit-font-smoothing: antialiased !important;
}

body,
input,
button {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.switch-wrapper {
  position: relative;
  margin: 0 !important;
  padding: 0 !important;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

body {
  margin: 0 auto !important;
  /* max-width: 520px !important; */
}

.fixed-bottom {
  margin: 0 auto !important;
  background: white;
  /* max-width: 520px !important; */
}

.fixed-bottom button {
  height: 45px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixed-bottom button svg {
  margin: 5px;
}

.product-description {
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  margin: 0 10px 10px 10px;
}
.home {
    height: 100vh;
}

.estabelecimento {
    cursor: pointer;
}

.estabelecimento:hover {
    background: #eee;
}
section#product-list {
    width: 100%;
    /* max-width: 580px; */
    margin: 0 auto;
    overflow-x: hidden;
}

a {
    color: black;
}

.title-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    padding: 10px;
    margin-top: 10px;
    border-bottom: 1px solid rgb(204, 204, 204);
    text-transform: uppercase;
}

.fantasia {
    text-align: center;
    width: 100%;
    padding: 15px;
    text-transform: uppercase;
    font-weight: bold;
}

.title-group:hover {
    cursor: pointer;
}

.img-modal {
    max-width: 100%;
    height: 350px;
}

/* ------------------------------------ */

#container-buttons-filter{
    box-sizing: border-box;
    overflow: auto;
    white-space: nowrap;
    display: flex;
    align-items: center;
    width: 100%;
}

#container-buttons-filter::-webkit-scrollbar {
    display: none;
}
  
#container-buttons-filter {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#container-buttons-filter .button-filter{
    background-color: rgba(0, 0, 0, 0);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14pt;
    border: none;
    margin-right: 10px;
    padding: 8px;
    color: rgb(0, 0, 0);
}

#container-buttons-filter .button-filter-active{
    border-bottom: 3px solid rgb(184, 0, 0);
    color: rgb(184, 0, 0);
}

#content-buttons-filter{
    margin-bottom: 0px;
    display: flex;
}

#content-buttons-filter .icons-filter-action{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #D9D9D9;
    margin: 5px;
}

.content-loader{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #333; /* Blue */
    border-radius: 50%;
    width: 32px;
    height: 32px;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
}

.empresa-fechada{
    opacity: 0;
}

.dsMensageminicial{
    background-color: #FFF;
    text-align: center;
    margin: 0;
}

.texto-empresa-fechada{
    background-color: #dc3545;
    color: white;
    text-align: center;
    margin: 0;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.btn-danger.disabled, .btn-danger:disabled {
    opacity: 1!important;
}

@media (min-width: 800px) {
    .content-product-list {
        display: ruby;
    }

    section#product-list article {
        width: calc(50% - 10px);
    }
}
header#main-header {
    min-height: 12vh;
    border-bottom: 1px solid #DDD;
    margin: 0 auto;
    flex-direction: column;
    background-color: #dc3545;
}

header#main-header .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    margin: auto;
    width: 100%;
}

header#main-header .logo img {
    object-fit: contain;
    margin: 20px 0 20px 0;
    min-height: 100px;
    max-width: 200px;
    border-radius: 10px;
}

header#main-header .empresa {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 !important;
    background-color: white;
    border-radius: 25px 25px 0 0;
    margin-top: 10px;
}

header#main-header .empresa .dados-empresa {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    width: 100%;
}

header#main-header .empresa .mais-info span {
    margin-right: 5px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

header#main-header .empresa .fantasia,
header#main-header .empresa .titulo{
    margin: 0 !important;
}
 
header#main-header .empresa .fantasia{
    font-size: 1.5rem;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
} 

header#main-header .empresa .endereco{
    margin: 0 !important;
    font-size: 13px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

header#main-header .empresa .endereco span{
    margin-left: 5px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.dsMensageminicial{
    margin-bottom: 0px !important;
    background-color: #EAEAEA;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

@media(max-width: 991px) {
    .info-icon {
        width: 24px;
        height: 24px;
    }
}
.ui-modal-mais-info {
  background-color: #fff;
  border-radius: 4px;
  max-width: 300px;
  margin: 32px auto 0 auto;
}

.ui-modal-overlay-mais-info {
  z-index: 9999 !important;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
section#product-list article {
    transition: border 0.2s;
    border-radius: 10px;
    border-top: none;
    border-right: 2px solid #dddddd;;
    border-bottom: 2px solid #dddddd;;
    border-left: none;
    margin: 5px;
    display: flex;
    flex-wrap: wrap;
}

section#product-list article:hover{
    border: 1px solid #DDD;
    cursor: pointer;
}

section#product-list article .product {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
}

.product-image-item {
    align-items: center;
    width: 25%;    
    padding-right: 10px;
}

section#product-list article .product-image-item div {
    width: 100%;
    height: 100%;
    background-color: #F5F5F5;
    display: grid;
    align-items: center;
    justify-content: center;
}

section#product-list article .product-image-item img{
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    align-self: center;
    border-radius: 10px 0px 0px 8px;
    background-position: center center;
    background-color: rgb(177 177 177);
    height: 100%;
}

section#product-list article .product-image-item-img{
    width: 100%;
    margin: 0.8rem;
    /* object-fit: cover; */
    /* display: flex; */
}

section#product-list article .product-name{
    font-family: 'Roboto';
    font-size: 19px;
    font-weight: 450; 
}

section#product-list article .product-info{    
    font-weight: lighter;
    font-size: 14px;
}

section#product-list article .product-value{
    width: 100%;    
    font-weight: 700;
    font-family: 'Roboto';
    font-size: 19px;
    color: rgb(128, 0, 0);    
    text-align: end;
}

article.empresa-fechada{
    opacity: 0.9;
}

.a-partir-de{
    font-size: 17px;
    color: black;       
    font-weight: lighter;
    margin-right: 5px;
}

.valor-antigo {
    color: rgb(74, 74, 74);
    font-size: 15px;
}
.ui-modal {
  background-color: #fff;
  border-radius: 4px;
  max-width: 300px;
  height: 300px;
  margin: 32px auto 0 auto;
}

.ui-modal .ui-modal-imagem{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ui-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-produto-imagem{
  object-fit: contain;
  width: 256px;
  height: 256px;
}
.content{
    padding-bottom: 15vh;
}

.content-img-product {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F5F5F5;
}

.button-select-flavor {
    font-size: 15px;
}

.product-value {
    width: 100%;
    font-weight: 700;
    font-family: 'Roboto';
    font-size: 19px;
    color: rgb(128, 0, 0);
    text-align: end;
    display: grid;  
    padding-right: 20px;
}

#content-items-flavors{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content-item-flavors{
    font-size: 16px;
    border-bottom: solid 1px #D3D3D3;
    width: 100%;
}

#content-button-flavors{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* .content-item-flavors{
    
} */


.content-item-flavors-added{
    color: #FFF;
    /* background-color: #DC3545; */
    background-color: #3d9bf8;
}

.button-select-flavor{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF !important;
}

.button-select-flavor svg{
    margin-left: 5px;
}

.btn-sabor-selecionado {
    color: #FFF !important;
    background-color: #3d9bf8;
}

.content-item-flavors-added .product-value{
    color: #FFF;
}

.content-item-flavors-added  .content-img-product{  
    /* background-color: #DC3545; */
    background-color: #3d9bf8;    
}

.content-img-product{
    width: 20%;
    padding: 0;
}

.details-pizza {
    width: 70%;
    min-height: 100%;
    display: grid;
    align-content: space-between;
    padding: 10px;
}

.content-img-product img {    
    border-radius: 10px 0px 0px 8px;
    background-color: rgb(177 177 177);
    width: 100%;    
    height: 100%;
}

.content-item-flavors-added button,
.content-item-flavors-added button:active,
.content-item-flavors-added button:hover
{
    color: #FFF;
}

.content-pizza .form-check-input{
    width: 25px;
    height: 25px;
}

.buttons-quantity-pizza {
    display: grid;
}

.del-quantity{
    background: rgb(238, 72, 72);
    width: 30px;
    height: 30px;
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.add-quantity{
    background: rgb(58, 248, 83);
    width: 30px;
    height: 30px;
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.del-quantity svg {
    color: white;
    margin: auto;
}

.add-quantity svg {
    color: white;
    margin: auto;
}
.container-img-pizza-quantity {
    display: flex;
}

.img-pizza-quantity{
    display: block; 
    margin-left: auto; 
    margin-right: auto
}
div#container {
    padding: 0px 16px;
    margin-bottom: 25vh;
}

.tela-produto input{
    height: 60px;
    word-wrap: break-word;
}

div#container #content-item{
    background-color: #FFF;
    border-bottom: solid 1px #D3D3D3;
    margin-bottom: 10px;
}

div#container #img-item{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

div#container .title-quantity {
    font-size: 21px;
}

div#container #decrease-quantity {
    width: 40px;
    height: 40px;
    background: rgb(255, 0, 0);
    color: #FFF;
    font-weight: bold;
    border-radius: 20px !important;
    border: solid 1px #D3D3D3;
}

div#container #increase-quantity {
    width: 40px;
    height: 40px;
    background: rgb(58, 248, 83);
    color: #FFF;
    font-weight: bold;
    border-radius: 20px !important;
    border: solid 1px #D3D3D3;
}

.product-img{
    margin: 10px;
    object-fit: cover;
    border-radius: 10px;
}

.description-product{
    width: 100%;
  }
html{  
    -webkit-user-select: none;
    -ms-user-select    : none;
    -o-user-select     : none;
    user-select        : none;
}

.product-note-content {
    vertical-align: middle;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 100px;
}

.product-note-content .titulo {    
    align-items: center;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    border-bottom: 1px solid rgb(204, 204, 204);
}

.product-note-content .titulo h6 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
}

.product-note-content .input-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.product-note-content .input-item-information {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.product-note-content .input-item .form-check{
    width: 100%;
}

.product-note-content .input-item .form-check .form-check-label{
    display: flex;
    align-items: center;
    font-size: 14px;
    text-transform: uppercase;
}

.product-note-content .input-item .form-check .form-check-input{
    display: flex;
    align-self: center;
}

.product-note-content .input-item .vl_item{
    margin-left: 5px;
    font-weight: bold;
    font-size: 13px;
}

.product-note-content .input-item .valor{
    font-weight: bold;
}

.product-note-content .input-item .input-quantidade{
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-note-content .input-item .input-quantidade .del-quantity,
.product-note-content .input-item .input-quantidade .add-quantity
{
    width: 30px;
    height: 30px;
    color: #FFF;
    font-weight: bold;
    border: none;
    border-radius: 20px;    
    align-items: center;
    display: flex;
}

.product-note-content .input-item .input-quantidade .del-quantity{
    background: rgb(238, 72, 72);
}

.product-note-content .input-item .input-quantidade .add-quantity{
    background: rgb(58, 248, 83);
}

.product-note-content .input-item .input-quantidade .del-quantity svg{
    margin: auto;
}

.product-note-content .input-item .input-quantidade .add-quantity svg{
    margin: auto;
}

.product-note-img {    
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
    object-fit: cover;
    border-radius: 10px;
    max-height: 150px;
}

.product-note .row{
    display: contents;
}

.espacamento{
    height: 55px;
}
.buttons-quantity-product {
    display: grid;
    align-content: space-between;
    width: 10%;
}

.content-details-product {
    margin: 10px;
    width: 65%;
    display: grid;
}

.item-select-product {
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
}

.add-remove-quantity-product {
    opacity: 0.6;
    width: 30px;
    text-align: center;
}
div#container-cart {
    padding: 0px 16px;

    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    
    flex: 1 1;
    height: calc(100vh - 164px);
}

.order-preview  {    
    flex:2 1;
    max-height: 60%;
    overflow-y: auto;
    overflow-x: hidden;
}

.order-totals {
    flex:1 1;
    max-height: 90px;
    height: auto;
}

.actions {
    flex:1 1;  
    /*max-height: 64px;  */
}

.order-title {
    padding: 15px;
}

.title-order-preview {
    font-size: 21px;
    font-weight: 500;
}

.order-products {
    padding: 15px;
    border-top: 1px solid #DDD;
    flex: 1 1;
    justify-content: center;
    margin-bottom: 200px;
}

.order-preview .wrapp-order-item {
    border-bottom: 1px solid #DDD;
}


.order-preview .order-item {
    display: flex;
    justify-content: space-between;
}

.order-preview .order-item .item-quantity {
    margin: 0px 4px;
}

.order-preview .order-item .item-description {
    margin: 0px 4px;
    width: 100%;
    font-weight: bold;
}

.order-preview .order-item .item-value {
    padding: 0px 8px;
    width: 40%;
}

.order-preview .order-item .item-remove {
    padding: 0px 8px;    
    width: 20%;    
    border: 0;
}

.order-preview .item-observation {
    padding-left: 32px;
    
    color: rgb(65, 65, 65);
    padding-bottom: 8px;
}

.order-preview .item-observation .observation {
    width: 100%; 
}

.more-items button {
    margin-top: 8px;
    width: 100%;
    color: #FFF;
    height: 48px;
    border: 0;
    border-radius: 3px;
    font-size: 21px;
    cursor: pointer;
    background-color: rgb(33, 125, 230);
}

.order-totals .title-totals{
    font-size: 21px;
    font-weight: 500;
}

.order-totals .wrapp-service-value{
    border-top: 1px solid #DDD;
    padding-top: 4px;
    display: flex;
    justify-content: space-between;
    height: auto;     
}

.order-totals .wrapp-amount{
    display: flex;
    justify-content: space-between;         
    padding: 0px 0px;
    border-bottom: 1px solid #DDD;
    font-size: 21px;
    font-weight: bold;
}

.actions button{
    width: 100%;
    border: 0;
    height: 48px;
    font-size: 21px;
    border-radius: 3px;
    color: white;
    background: rgb(69, 219, 102);
}

.item-description{
    font-size: 20px;
    font-weight: 600;
}
.order-item{
    padding: 4px;
    margin-top: 8px;
}

.item-remove {
    padding: 4px;
    border: 0;
    background-color: transparent;
}

.pizzas-sabores{
    padding-left: 10px;
}

.item-description{
    font-size: 20px;
    font-weight: 600;
}

.observacoes .observacao-ds_obs{
    display: flex;
    align-items: center;
    justify-content: center;
}

.observacoes .observacao-itens{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
div#container-complement {
    padding: 0px 2px;
    height: calc(var(--vh) - 64px);/*calc(100vh - 64px);*/
    min-height: 155px;
    display: flex;
    flex-direction: column;
}

.title-complement {
    padding: 16px;
    font-size: 21px;
    color: rgb(219, 90, 81);
}

.title-add-complement {
    padding: 16px;
    font-size: 21px;
    color: rgb(82, 126, 255);
    background-color: #eeeeee;
}

div#container-complement .list-complements {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow: auto;
}

.list-group .title-complement-not-found{
    text-align: center;
    font-size: 21px;
    font-weight: 500;
    color: darkgrey;
    margin-top: 100px;
}

div#container-complement .wrappButtons {
    width: 100%;
    height: 48px;        
    display: flex;
    justify-content: space-between;
}

div#container-complement .action-next {
    height: 48px;
    width: 60%; 
}

div#container-complement .action-back {
    height: 48px;
    width: 38%; 
}

div#container-complement .action-next button {   
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 3px;
    background: #4bb0ee;    
    
    font-weight: 500;
    font-size: 21px;
    color: #fff;
}

div#container-complement .action-back button {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 3px;
    background: #d5d5d5;    
    
    font-weight: 500;
    font-size: 21px;
    color: #fff;
}
div#container-complement .list-complements {
    margin: 12px 0px;
}

div#container-complement .list-complements .inputCheckbox {    
    display: flex;    
    align-items: center;
    width: 100%;
}

div#container-complement .list-complements .wrappCheckbox {    
    display: flex;    
    justify-content: space-between;
    padding: 16px 0px; 
    border-bottom: 1px solid #a5a5a5a5;       
}

div#container-complement .list-complements label {     
    margin-left: 8px;
    width: 100%;
}

div#container-complement .list-complements span {
    font-weight: 500;
}

li.list-group-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

li.list-group-item .form-check-label{
    font-size: 19px;
}

.label-add-complement{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100% !important;
    margin: 0 !important;
}
.info-qtadicional{
    color: #222;
    font-size: 18px;
    margin: 0;
}
.container-info-user {
    padding: 4px 16px;
}

/* * { 
    -webkit-user-select:text;
    -khtml-user-select:text;
    -moz-user-select:text;
    -ms-user-select:text;
    user-select:text;
} */

.tela-usuario {
    margin-bottom: 100px;
}

/* Aqui vai os estilos quando não tem nada escrito no campo */
.tela-usuario input::-webkit-input-placeholder{
    font-weight: 900;
    color: gray;
}
.tela-usuario input:-ms-input-placeholder{
    font-weight: 900;
    color: gray;
}
.tela-usuario input::placeholder{
    font-weight: 900;
    color: gray;
}

/* aqui vai os estilos quando escrever no campo */
.tela-usuario input{
    font-weight: 900;
    color: #000;
}

.container-info-user input {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    height: 44px;
    padding: 8px 12px;
    font-size: 17px;
    width: 100%;
    margin-top: 8px;
    resize: none;
    font-family: Roboto;
}

div#wrappAddress {
    display: flex;
    justify-content: space-between;
}


div#wrappAddress .address {
    width: 72%;
    margin-right: 8px;
}

div#wrappAddress .numberAddress {
    width: 20%;
}

.entregar-buscar-pedido{
    font-size: 19px;
    text-transform: uppercase;
    font-weight: bold;
}

.ui-modal-agendamento {
    background-color: #fff;
    border-radius: 4px;
    max-width: 300px;
    height: 300px;
    margin: 32px auto 0 auto;
}
  
.ui-modal-overlay-agendamento {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.fixed-bottom{
    z-index: 9 !important;
}

.inputBase{
    width: 100%;
    margin-top: 7px;
}

.inputEndereco{
    width: calc(75% - 7px);
    margin-top: 7px;
}

.inputNumero{
    width: 25%;
    margin-top: 7px;
    margin-left: 7px;
}

.inputInterno {
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.inputInterno:disabled {
    background-color: #e9ecef
}

.inputInterno:focus {
    border-color: rgb(41, 81, 239);
    box-shadow: 0 0 5px rgb(38, 118, 246);
    border-width: 0;
}
#container-checkout {
    padding: 8px 16px;
    display: block;
}

#container-checkout span {
    display: block;
}

#container-checkout span.title-info-total {
    font-size: 17px;
    display: flex;
    align-items: center;
    /* border-bottom: #222 solid 1px;     */
}

#container-checkout span.title-info-total span.label-totals{
    margin-left: 5px;
}

#container-checkout span.title-info-total-final {
    font-size: 21px;
    border-bottom: #1274b1 solid 2px;    
}

.container-info-checkout {
    margin-bottom: 100px;
}

.items-payment {
    margin-top: 8px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px;
    gap: 32px;
    list-style: none;
}

.items-payment li {
    background: #ececec;
    border: 2px solid #f5f5f5;
    height: 80px;
    border-radius: 8px;
  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  
    text-align: center;
  
    cursor: pointer;
}

.items-payment li span {
    flex: 1 1;
  
    display: flex;
    align-items: center;
    color: var(--title-color)
}

.items-payment li img {
    margin-top: 12px;
    width: 48px;
    height: 36px;
}

#container-checkout fieldset {
    margin: 8px 0px 16px 0px;
    min-inline-size: auto;
    border: 0;
  }
  
.items-payment li.selected {
    background: #e2f4ff;
    border: 2px solid #5faada;
}

#container-checkout span.label-totals {
    font-weight: bold;
    font-size: 23px;
    /* padding-bottom: 8px;     */
}

#container-checkout .wrappButtons {
    width: 100%;
    height: 48px;       
}

#container-checkout .action-next {
    height: 48px;
}

#container-checkout .action-back {
    height: 48px;
    margin-top: 8px;
}

#container-checkout .action-next button {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 3px;
    background: #4bb0ee;
    
    font-weight: 500;
    font-size: 21px;
    color: #fff;
}

#container-checkout .action-back button {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 3px;
    background: #d5d5d5;    
    
    font-weight: 500;
    font-size: 21px;
    color: #fff;
}

label.handle-cupom {
    margin-bottom: 2.5px!important;
}
input#handle-cupom {
    border-right: 0!important;
}

input#handle-cupom:focus {
    box-shadow: none!important;
    border-color: #28a745!important;
}

.btn-cupom:focus {
    box-shadow: none;
}

.btn-remover-cupom:focus {
    box-shadow: none;
}
div.container-main {    
    /* height: calc(var(--vh) - 64px); */
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 10px;
}

.status-icone{
    font-size: 28px;
}

.status-texto{
    font-size: 23px;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

section#header-info h3 {
    padding: 8px;
    text-align: center;
}

section#header-info h4 {
    padding-top: 12px;
    height: 48px;
    text-align: center;
    font-weight: lighter;
    font-size: 26px;
}

main {
    text-align: center;
}

main h2 {    
    color: #404040;
}

main h3 {    
    color: #233ba8;
    font-weight: bold;
    font-size: 30px;
}

main figure span {
    font-size: 42px;  
}

footer article.info-address {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px;
    background-color: rgb(240, 240, 240);
}

footer article.info-address img {
    margin: 0px 8px;
    height: 36px;
    width: 36px;
}

footer article.footer-gerence {
    display: flex;
    min-height: 52px;
    height: 52px;
    justify-content: flex-end;    
    margin-right: 8px;    
    border-top: 1px solid #DDD;
    padding: 8px 0px;
}

footer article.footer-gerence img {    
    height: 36px;
    margin-left: 8px;
}
.borda-espacamento:last-of-type {
    margin-bottom: 3rem!important;
}
/* html {
    background-color: #000;
} */

.transition-enter {
    opacity: 0;
    /* transform: translateY(-100vh) !important; */
    z-index: 1 !important;
}

.transition-enter.transition-enter-active {
    opacity: 1;
    /* transform: translateY(0vh) !important; */
    transition: opacity 200ms ease-in;
    /* transition: transform 500ms ease-in !important; */
}
